import { get, set, del, clear } from 'idb-keyval'
import {
    PersistedClient,
    Persister,
} from '@tanstack/react-query-persist-client'

export function createIDBPersister(idbValidKey: IDBValidKey = 'portais-ecommerce') {
    return {
        persistClient: async (client: PersistedClient) => {
            await set(idbValidKey, client)
        },
        restoreClient: async () => {
            return await get<PersistedClient>(idbValidKey)
        },
        removeClient: async () => {
            await del(idbValidKey)
        },
    } satisfies Persister
};

export function clearIDB() {
    clear()
}