import './styles.css'
import Logo from '../Logo';
import { useState, useEffect } from 'react';

interface SplashScreenProps {
    show: boolean;
}

export default function SplashScreen({
    show
}: SplashScreenProps) {
    const [ splashVisible, setSplashVisible ] = useState(show);
    const fadeOutTime = 1000;
    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setSplashVisible(false);
            }, fadeOutTime);
        } else {
            document.title = 'Autenticando... | Portal E-commerce Cielo';
        }
    }, [show]);

    return (
        <div className="splash-screen__wrapper"
            data-authenticated={!show}
            style={{
                display: splashVisible ? 'flex' : 'none',
                '--fade-out-time': `${fadeOutTime}ms`
            } as React.CSSProperties}>
            <div 
                className="splash-screen__logo">
                <Logo />
            </div>
        </div>
    );
};