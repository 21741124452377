const configs = {
    DD_APP_ID: import.meta.env.VITE_DD_APP_ID,
    DD_TOKEN: import.meta.env.VITE_DD_TOKEN,
    DD_ENV: import.meta.env.VITE_DD_ENV,
    HJ_ID: import.meta.env.VITE_HJ_ID,
    SSO_API_URL: import.meta.env.VITE_SSO_API_URL,
    BFF_SPLIT_URL: import.meta.env.VITE_BFF_SPLIT_URL,
    MERCHANT_API_URL: import.meta.env.VITE_MERCHANT_API_URL,
    BFF_OPERATION_URL: import.meta.env.VITE_BFF_OPERATION_URL,
    DATADOG_VERSION: import.meta.env.VITE_DATADOG_VERSION
};

export const datadogScript = `
    (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
    })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js','DD_RUM')
    window.DD_RUM.onReady(function() {
        window.DD_RUM.init({
            applicationId: '${configs.DD_APP_ID}',
            clientToken: '${configs.DD_TOKEN}',
            site: 'datadoghq.com',
            service: 'Portal',
            env: '${configs.DD_ENV}',
            version: '${configs.DATADOG_VERSION}',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "allow",
            forwardConsoleLogs: ["log", "error", "warn"],
            allowedTracingUrls: ["${configs.SSO_API_URL}", "${configs.BFF_SPLIT_URL}", "${configs.MERCHANT_API_URL}", "${configs.BFF_OPERATION_URL}"]
        });
        window.DD_RUM.startSessionReplayRecording();
    })
    `;

export const mouseFlowScript = `
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/13a3ff0c-bf05-4d3c-82d8-e467e1009340.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
`;