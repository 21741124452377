import { ResultState } from "ecommerce-flui-storybook";
import { emitEvent } from "@utils/events";
import { useEffect } from "react";

export default function PageNotFound() {
    useEffect(() => {
        setTimeout(() => {
            emitEvent('SubHeaderEvent', {title: 'Página não encontrada'});
        }, 100)
    }, []);
    return (
        <div className='root__router'>
            <ResultState 
                type="not-found"
                width="300px"
                height="300px"
                text="Não encontramos a página que você está procurando, por favor tente novamente."
            />
        </div>
    )
}