import { __federation_method_setRemote, __federation_method_getRemote } from '__federation__';
import { Remote, Module } from '../interfaces';
import { lazy } from 'react';

export const RemoteModule = (remote: Remote, moduleName: string) => lazy(async () => {
	const config = {
		url: `${remote.route.toLowerCase()}/assets/ecommerce-mfe-${remote.projectName.replace('ecommerce-mfe-', '')}-entry.js`,
		name: `@${remote.projectName}`,
		module: `./${moduleName}`
	};

	const { url, name, module } = config;

	__federation_method_setRemote(name, {
		url: () => Promise.resolve(url),
		format: 'esm',
		from: 'vite',
	});

	const remoteModule = await __federation_method_getRemote(name, module);
	return { default: remoteModule.default ? remoteModule.default : remoteModule};
});

export const processRemotesModulesMappers = async (remote: Remote): Promise<Module[]> => {
	const config = {
		url: `${remote.route.toLowerCase()}/assets/ecommerce-mfe-${remote.projectName.replace('ecommerce-mfe-', '')}-entry.js`,
		name: `@${remote.projectName}`,
		module: './ModulesMapper'
	};

	const { url, name, module } = config;

	__federation_method_setRemote(name, {
		url: () => Promise.resolve(url),
		format: 'esm',
		from: 'vite',
	});

	return await __federation_method_getRemote(name, module);
};

export const addScriptsToHead = (...scripts: string[]) => {
    const head = document.head;
    scripts.forEach(script => {
        const scriptElement = document.createElement('script');
        scriptElement.textContent = script;
        head.appendChild(scriptElement);
    });
}