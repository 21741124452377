import { ReactNode } from "react";
import ErrorPage from "../ErrorPage";
import React from "react";

interface Props {
  text?: string;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage text={this.props.text}/>;
    }

    return this.props.children;
  }
}