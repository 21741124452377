import { axios } from '@utils/infra';
import { Application, IAddAttribute, Remote } from '../interfaces';

export const getRemotes = async (): Promise<Remote[]> => {
	try {
		const response = await axios.get('/application/getAllMfes');
		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const setLastLoginDate = async () => {
	try {
		const response = await axios.post('/User/LastLoginDate');
		return response.data;
	} catch (error) {
		console.log('lastLoginDate error: ', error);
		throw error;
	}
};

export const addAttribute = async (data: IAddAttribute, userId: string) => {
	try {
		const response = await axios.post(`/User/Attribute/${userId}`, data);
		return response.data;
	} catch (error) {
		console.log('addAttribute error: ', error);
		throw error;
	}
};

export const getApplicationsById = async (appId: number): Promise<Application> => {
	try {
		const response = await axios.get('/application/details',
			{
				params: {
					applicationId: appId,
					isprotobuf: false,
				},
			}
		);

		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
