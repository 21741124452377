import { Button, ResultState } from "ecommerce-flui-storybook";
import { useEffect } from "react";
import { emitEvent } from "@utils/events";
import { clearIDB } from "../../utils/persistClientHandler";

export default function ErrorPage({ text }: { text?: string }) {
    useEffect(() => {
        setTimeout(() => {
            emitEvent('SubHeaderEvent', { title: 'Oops, algo deu errado!' });
        }, 100)
    }, []);

    const clearCacheAndReload = async () => {
        console.log('Clearing cache and reloading page...');
        if ('caches' in window) {
            const cacheKeys = await caches.keys();
            await Promise.all(cacheKeys.map((key) => caches.delete(key)));
        }
        clearIDB();
        window.location.reload();
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: '20px',
                width: "100vw"
            }}>
            <ResultState
                id="error-page"
                type="error"
                width="300px"
                height="300px"
                text={text ? text : "Não foi possível carregar a página, por favor tente novamente."}
            />
            <Button
                id="try-again"
                text="Recarregar página"
                action={clearCacheAndReload}
            />
        </div>
    )
}