import App from './App.tsx';
import ReactDOM from 'react-dom/client';
import './themes/cielo/cielo.css';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createIDBPersister } from './utils/persistClientHandler.ts';
import { Workbox } from 'workbox-window';

const persistedIndexexDb = createIDBPersister();


const wb = new Workbox('/sw.js');
wb.register();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
        }
    }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister: persistedIndexexDb }}
    >
        <App />
    </PersistQueryClientProvider>,
);