import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { SmallCard, ResultState, Skeleton } from 'ecommerce-flui-storybook';
import { emitEvent } from '@utils/events';
import './styles.css';
import { getApplicationsById } from '../../apis';
import { Application } from '../../interfaces';

const MAIN_APP_ID = import.meta.env.VITE_MAIN_APP_ID;

export default function InternalAccess() {

	const { data, isLoading, isError } = useQuery({
		queryKey: ['applications'],
		queryFn: () => getApplicationsById(MAIN_APP_ID)
	});

	const navigate = useNavigate();

	emitEvent('SubHeaderEvent', {
		title: 'Área Interna'
	});

	const ResultStateText = isError
		? 'Ocorreu um erro ao carregar as informações. Por favor, tente novamente dentro de alguns instantes.'
		: 'Nesta área serão exibidas as aplicações que forem cadastradas.';

	if (isLoading) return (
		<div className="internal-access__container">
			<Skeleton height="154px" width="424px" type="card" />
			<Skeleton height="154px" width="424px" type="card" />
			<Skeleton height="154px" width="424px" type="card" />
		</div>
	);

	return (
		<>
			{data?.childs && (
				<main className="internal-access__container">
					{data?.childs.map((app: Application) => (
						<SmallCard
							key={app.id}
							isNew
							title={app.name}
							content={app.description}
							action={() => navigate(`/${app.projectName}`)}
						/>
					))}
				</main>
			) || <ResultState type={isError ? 'error' : 'empty'} width={'200px'}
				text={ResultStateText} />}
		</>
	);
}
