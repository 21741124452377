import Keycloak, { KeycloakConfig } from 'keycloak-js';
import AppLoader from './components/AppLoader';
import useAuthentication from './hooks/auth/index.tsx';
import { QueryClient, QueryClientProvider } from 'react-query';
import { mouseFlowScript, datadogScript } from './scripts/index.ts';
import { addScriptsToHead } from './utils/index.ts';

const configs = {
	RHSSO_CLIENT_ID: import.meta.env.VITE_RHSSO_CLIENT_ID,
	RHSSO_URL: import.meta.env.VITE_RHSSO_URL,
	RHSSO_REALM: import.meta.env.VITE_RHSSO_REALM
};

const keycloakConfig: KeycloakConfig = {
	clientId: configs.RHSSO_CLIENT_ID, // 'NovoPortal',
	url: configs.RHSSO_URL, // 'https://me.hdevelo.com.br/auth/',
	realm: configs.RHSSO_REALM // 'PortaisEcommerceSBOX'
};

const keycloak = new Keycloak(keycloakConfig);

keycloak.init({
	onLoad: 'login-required',
	checkLoginIframe: false,
	idToken: localStorage.getItem('kcIdToken') || '',
	token: localStorage.getItem('kcToken') || '',
	refreshToken: localStorage.getItem('kcRefreshToken') || ''
});

addScriptsToHead(
	mouseFlowScript,
	datadogScript,
);

export default function App() {
	const queryClient = new QueryClient();
	const { isAuthenticated } = useAuthentication(keycloak);

	return (
		<QueryClientProvider client={queryClient}>
			<AppLoader isAuthenticated={isAuthenticated} />
		</QueryClientProvider>
	);
}