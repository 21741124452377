import { Button, ResultState } from 'ecommerce-flui-storybook';
import { emitEvent } from '@utils/events';
import { useNavigate } from 'react-router-dom';
import { UserHasRequiredRoles } from '@utils/validations';

interface Props {
	requiredRoles: string[];
	children: JSX.Element;
}

export default function PrivateRoute({ requiredRoles, children }: Props) {
	
	const navigate = useNavigate();

	if (UserHasRequiredRoles(requiredRoles)) {
		return children;
	}

	emitEvent('SubHeaderEvent', {
		title: 'Acesso negado'
	});

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '32px',
				width: '100%',
				marginTop: '20px'
			}}
		>
			<ResultState 
				type='user-forbidden' 
				width='200px' 
				text='Seu usuário não possui acesso a essa página. '
			/>
			<div>
				<Button text='Ir para o início' action={() => navigate('/')}/>
			</div>
		</div>
	);
}
