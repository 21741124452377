import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Route } from 'react-router-dom';
import { Remote, Module } from '../interfaces';
import { getRemotes } from '../apis';
import ErrorBoundary from '../components/ErrorBoundary';
import PrivateRoute from '../components/PrivateRoute';
import {
	processRemotesModulesMappers,
	RemoteModule
} from '../utils';

export function useConfigRoutes(isAuthenticated: boolean) {
	const { data: remotes, isLoading, isError } = useQuery({
		queryKey: ['getRemotes'], 
		queryFn: getRemotes,
		enabled: isAuthenticated}
	);

	const [routes, setRoutes] = useState<JSX.Element[]>([]);
	const [loadingRoutes, setLoadingRoutes] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		setError(isError);
		if (!isLoading && remotes) {
			Promise.all(
				remotes.map(async (remote: Remote) => {
					try{
						const modules = await processRemotesModulesMappers(remote);

						const newRoutes = modules.map((module: Module) => {
							const Component = RemoteModule(remote, module.name);
							return (
								<Route key={module.path} path={module.path} element={
									<PrivateRoute requiredRoles={module.requiredRoles as string[] ?? []}>
										<ErrorBoundary>
											<Component />
										</ErrorBoundary>
									</PrivateRoute>
								}/>
							);
						});

						return newRoutes;
					}
					catch(_){
						setError(isError);
						setLoadingRoutes(false);
						return;
					}
				})
			).then((allRoutes: any) => {
				setRoutes(allRoutes.flat());
			}).finally(() => {
				setLoadingRoutes(false);
			});
		}
	}, [remotes, isError]);

	return { routes, loadingRoutes, error };
}