import { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ConfigRoutes from '../../routes';
import SplashScreen from '../SplashScreen';
import './styles.css';
import { useConfigRoutes } from '../../hooks';
import ErrorBoundary from '../ErrorBoundary';

const retry = (fn: any, retriesLeft = 3, interval = 1000): Promise<{ default: React.ComponentType<any> }> => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error: any) => {
				console.warn(`Failed to import Header, retries remaining: ${retriesLeft}`, error);
				if (retriesLeft === 1) {
					reject(error);
					return;
				}
				setTimeout(() => {
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

const loadHeader = () => retry(() => import('@header/Header'));

const Header = lazy(loadHeader);

interface AppLoaderProps {
	isAuthenticated: boolean;
}

export default function AppLoader({ isAuthenticated }: AppLoaderProps) {
	const { routes, error, loadingRoutes } = useConfigRoutes(isAuthenticated);
	const enableSplashScreen = !isAuthenticated || loadingRoutes;

	return (
		<>
			<SplashScreen show={enableSplashScreen} />
			<BrowserRouter>
				<Suspense fallback={<SplashScreen show={true} />}>
					<ErrorBoundary>
						<Header />
						<main id="root-content-wrapper" tabIndex={0} className="content-routes__wrapper">
							{routes && <ConfigRoutes routes={routes} routesError={error} routesIsLoading={loadingRoutes} />}
						</main>
					</ErrorBoundary>
				</Suspense>
			</BrowserRouter>
		</>
	);
}
