import { Icon } from "ecommerce-flui-storybook";
import { emitEvent } from "@utils/events";
import { useEffect } from "react";

export default function LoadingPage() {
    useEffect(() => {
        setTimeout(() => {
            emitEvent('SubHeaderEvent', {title: 'Carregando...'});
        }, 100)
    }, []);
    
    return (
        <div className='root__router'>
            <Icon id="router-spinner" name="spinner" color="#0774E7" width="50px" height="50px" hasInfinityRotation  />
        </div>
    )
}