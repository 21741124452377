import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import InternalArea from './components/InternalArea';
import PrivateRoute from './components/PrivateRoute';
import PageNotFound from './components/PageNotFound';
import LoadingPage from './components/LoadingPage';
import ErrorPage from './components/ErrorPage';

interface ConfigRoutesProps {
	routes: JSX.Element[];
	routesError?: boolean;
	routesIsLoading?: boolean;
}

export default function ConfigRoutes({ routes, routesError, routesIsLoading }: ConfigRoutesProps) {

	if (routesError) return <ErrorPage />;

	return (  
		<Suspense fallback={<LoadingPage />}>
			<Routes>
				{routes}
				<Route
					path='*'
					element={routesIsLoading ? <LoadingPage /> : <PageNotFound />}
				/>
				<Route key="internal-access" path="/applications/internal-area" element={
					<PrivateRoute requiredRoles={['InternalAccess']}>
						<ErrorBoundary>
							<InternalArea />
						</ErrorBoundary>
					</PrivateRoute>
				}/>
			</Routes>
		</Suspense>
	);
}