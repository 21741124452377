import Keycloak from "keycloak-js";
import {
    useKeycloakStore,
    useUserStore
} from '@utils/global-stores';
import { useMerchant } from '@utils/hooks';
import { useState } from "react";
import { setLastLoginDate } from '../../apis';
import { filters } from '@utils/constants';
import { clearIDB } from '../../utils/persistClientHandler';


function useAuthentication(keycloak: Keycloak) {
    const keycloakState = useKeycloakStore();
    const userState = useUserStore();
    const { getFilterMerchant } = useMerchant();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const handleLogout = () => {
        clearIDB();
        keycloak.logout();
    };

    keycloak.onTokenExpired = () => {
        keycloak.updateToken(5).then((refresh) => {
            if (!refresh) {
                keycloak.login();
            }
        }).catch(error => {
            console.error('Failed to refresh token: ', error);
            keycloak.login();
        });
    };

    const updateTokenLocalStorage = () => {
        keycloak.idToken && localStorage.setItem('kcIdToken', keycloak.idToken);
        keycloak.token && localStorage.setItem('kcToken', keycloak.token);
        keycloak.refreshToken && localStorage.setItem('kcRefreshToken', keycloak.refreshToken);
    };

    keycloak.onAuthRefreshSuccess = () => {
        updateTokenLocalStorage();
    };

    keycloak.onAuthSuccess = () => {
        keycloakState.setKeycloak({
            keycloak
        });
        updateTokenLocalStorage();
        setIsAuthenticated(keycloak.authenticated || false);
        keycloak.loadUserInfo().then(async (userInfo: any) => {
            if (userInfo.user.blocked === 'True' || userInfo.user.enabled === 'false') {
                handleLogout();
                return;
            };
            const filterMerchantId = localStorage.getItem(filters.filterMerchantId);
            const filterMerchantOnlyBind = localStorage.getItem(filters.filterMerchantOnlyBind);
            const userRoles = keycloak.tokenParsed?.resource_access?.PortalEcomm.roles;
            getFilterMerchant();
            userState.setUser((prev: any) => ({
                ...prev,
                ...userInfo,
                roles: userRoles,
                lastMerchant: filterMerchantId && JSON.parse(filterMerchantId),
                lastMerchantOnlyBind: filterMerchantOnlyBind && JSON.parse(filterMerchantOnlyBind)
            }));
            window.DD_RUM.setUser({
                id: `${userInfo.sub}`,
                email: `${userInfo.email}`
            });
            setLastLoginDate();
        }).catch((error) => {
            console.error('Failed to get user info:', error);
            handleLogout();
        });
    };

    return {
        isAuthenticated
    }
};

export default useAuthentication;