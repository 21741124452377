export default function Logo({ width = '250', height = '250' })  {
	return (
		<svg width={width} height={height} viewBox="0 0 98 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M57.9169 0.341763C57.2474 0.341763 56.7031 0.883606 56.7031 1.55549V16.1515C56.7031 16.7728 57.1486 17.3267 57.7651 17.4014C58.502 17.4905 59.1306 16.9173 59.1306 16.1973V1.55308C59.1306 0.883606 58.5887 0.339355 57.9169 0.339355" fill="#5B636D"/>
		<path d="M38.2206 4.29147C37.5511 4.29147 37.0068 4.83331 37.0068 5.5052V16.1518C37.0068 16.7731 37.4524 17.327 38.0688 17.4016C38.8058 17.4907 39.4343 16.9176 39.4343 16.1976V5.50279C39.4343 4.83331 38.8924 4.28906 38.2206 4.28906" fill="#5B636D"/>
		<path d="M39.776 1.55569C39.776 2.43468 39.0487 3.10897 38.1625 3.07766C37.3509 3.04877 36.6718 2.37447 36.667 1.56291C36.6622 0.700782 37.3606 0 38.2203 0C39.08 0 39.776 0.695966 39.776 1.55569Z" fill="#00ADEE"/>
		<path d="M48.1594 6.35526C50.0595 6.35526 51.685 7.53286 52.3473 9.19933C52.4701 9.50517 52.2389 9.8375 51.909 9.8375H44.4123C44.0824 9.8375 43.8512 9.50517 43.974 9.19933C44.6362 7.53286 46.2594 6.35526 48.1618 6.35526M54.5122 8.1614C53.3467 5.52684 50.5893 3.67013 47.4442 3.9856C44.1281 4.31793 41.5056 7.02955 41.2672 10.3553C40.9782 14.401 44.1763 17.7725 48.1594 17.7725C50.0378 17.7725 51.4321 17.05 52.3087 16.395C52.8217 16.0121 52.9782 15.2391 52.6097 14.7189C52.2004 14.1409 51.4225 14.0398 50.8903 14.442C50.281 14.9043 49.2816 15.4317 47.9475 15.3619C46.1462 15.268 44.6122 14.1241 43.9716 12.5226C43.8488 12.2168 44.0824 11.8845 44.4099 11.8845H52.8313C53.9945 11.8845 54.8855 10.9429 54.8855 9.8399C54.8855 9.23545 54.688 8.56356 54.5098 8.16381" fill="#5B636D"/>
		<path d="M67.8552 15.3643C65.3676 15.3643 63.3495 13.3486 63.3495 10.8585C63.3495 9.7267 63.7661 8.69599 64.4549 7.9037L62.6247 6.33838C61.4832 7.65807 60.833 9.41604 60.9582 11.3257C61.1822 14.7357 63.9419 17.51 67.3519 17.7484C69.6662 17.9121 71.7613 16.932 73.1268 15.3185L71.299 13.7556C70.4729 14.7357 69.2351 15.3619 67.8528 15.3619" fill="#5B636D"/>
		<path d="M67.8556 3.94967C65.7677 3.94967 63.8941 4.87683 62.6274 6.341L64.4577 7.90633C65.3511 6.87803 66.7021 6.25913 68.1952 6.3675C70.3794 6.52644 72.1615 8.28682 72.3469 10.4686C72.4529 11.7257 72.0435 12.8841 71.3041 13.7582L73.132 15.3211C74.153 14.117 74.7671 12.5589 74.7671 10.8588C74.7671 7.04179 71.6726 3.94727 67.8556 3.94727" fill="#00ADEE"/>
		<path d="M34.7744 14.4948C34.3433 14.1263 33.7076 14.095 33.2548 14.4394C32.4963 15.0198 31.5498 15.3641 30.524 15.3641C27.9785 15.3641 25.9243 13.2522 26.0231 10.685C26.1097 8.4117 27.8388 6.57907 30.1025 6.37437C31.2874 6.266 32.3879 6.62001 33.2476 7.27263C33.6329 7.56643 34.17 7.60736 34.5842 7.36173C35.3403 6.91381 35.3813 5.87588 34.719 5.37016C33.4403 4.39003 31.8099 3.84818 30.0495 3.96618C26.5721 4.19737 23.7883 7.03422 23.6197 10.5165C23.427 14.49 26.5914 17.7723 30.5215 17.7723C32.1085 17.7723 33.5703 17.2377 34.7359 16.337C35.3307 15.8795 35.3427 14.9836 34.772 14.4972" fill="#5B636D"/>
		<path d="M4.12523 37.0165H1.34618L0.770619 38.3241H0L2.36725 33.096H3.10656L5.48103 38.3241H4.69597L4.12041 37.0165H4.12523ZM3.86273 36.4193L2.7357 33.857L1.60867 36.4193H3.86514H3.86273ZM2.8537 32.6697H2.18182L3.19807 31.6992H4.10837L2.8537 32.6697Z" fill="#5B636D"/>
		<path d="M8.28411 34.3286V35.0246C8.22391 35.0174 8.17093 35.0174 8.12035 35.0174C7.34973 35.0174 6.87291 35.487 6.87291 36.3539V38.3262H6.15527V34.3671H6.84161V35.0318C7.09447 34.5694 7.58814 34.3286 8.28411 34.3286Z" fill="#5B636D"/>
		<path d="M12.6914 36.585H9.4789C9.56801 37.2786 10.1291 37.7434 10.9142 37.7434C11.3766 37.7434 11.7667 37.5869 12.0581 37.2642L12.453 37.7265C12.0942 38.1456 11.5499 38.3695 10.8925 38.3695C9.61617 38.3695 8.76367 37.5242 8.76367 36.3442C8.76367 35.1642 9.60895 34.3262 10.7504 34.3262C11.8919 34.3262 12.7083 35.1474 12.7083 36.3659C12.7083 36.4261 12.7011 36.5152 12.6938 36.5826L12.6914 36.585ZM9.4789 36.0697H12.0195C11.9449 35.405 11.444 34.933 10.7504 34.933C10.0569 34.933 9.55596 35.3954 9.48131 36.0697H9.4789Z" fill="#5B636D"/>
		<path d="M16.725 35.9349V38.3262H16.0459V37.8037C15.8074 38.1625 15.3667 38.372 14.7454 38.372C13.8496 38.372 13.2812 37.8928 13.2812 37.2064C13.2812 36.5707 13.6931 36.0481 14.8731 36.0481H16.0073V35.906C16.0073 35.3016 15.6557 34.95 14.9477 34.95C14.4781 34.95 13.9989 35.1137 13.6931 35.3762L13.3944 34.8392C13.8062 34.5117 14.3962 34.3311 15.0296 34.3311C16.1133 34.3311 16.725 34.8536 16.725 35.9373V35.9349ZM16.0073 37.1149V36.561H14.902C14.1915 36.561 13.9917 36.838 13.9917 37.1727C13.9917 37.5677 14.3192 37.8157 14.8658 37.8157C15.4125 37.8157 15.8291 37.5701 16.0097 37.1125L16.0073 37.1149Z" fill="#5B636D"/>
		<path d="M23.798 32.7827V38.3263H23.1116V37.6978C22.7913 38.1457 22.2832 38.3697 21.7004 38.3697C20.5421 38.3697 19.6992 37.5557 19.6992 36.3444C19.6992 35.1331 20.5445 34.3263 21.7004 34.3263C22.2615 34.3263 22.7528 34.5358 23.0827 34.9621V32.7803H23.8004L23.798 32.7827ZM23.0899 36.3444C23.0899 35.5015 22.5144 34.9549 21.7606 34.9549C21.0069 34.9549 20.4241 35.4991 20.4241 36.3444C20.4241 37.1897 20.9996 37.7411 21.7606 37.7411C22.5216 37.7411 23.0899 37.1897 23.0899 36.3444Z" fill="#5B636D"/>
		<path d="M28.7202 36.585H25.5077C25.5968 37.2786 26.1579 37.7434 26.943 37.7434C27.4054 37.7434 27.7955 37.5869 28.0869 37.2642L28.4818 37.7265C28.123 38.1456 27.5788 38.3695 26.9213 38.3695C25.645 38.3695 24.7925 37.5242 24.7925 36.3442C24.7925 35.1642 25.6378 34.3262 26.7792 34.3262C27.9207 34.3262 28.7371 35.1474 28.7371 36.3659C28.7371 36.4261 28.7299 36.5152 28.7226 36.5826L28.7202 36.585ZM25.5077 36.0697H28.0483C27.9737 35.405 27.4728 34.933 26.7792 34.933C26.0857 34.933 25.5848 35.3954 25.5101 36.0697H25.5077Z" fill="#5B636D"/>
		<path d="M31.2363 37.9072L31.5349 37.3389C31.8721 37.5773 32.4091 37.7507 32.9245 37.7507C33.5891 37.7507 33.8661 37.5484 33.8661 37.2137C33.8661 36.325 31.364 37.0932 31.364 35.5183C31.364 34.8079 31.9997 34.3311 33.016 34.3311C33.5313 34.3311 34.1141 34.4659 34.4585 34.6899L34.1526 35.2582C33.7938 35.027 33.3989 34.9451 33.0088 34.9451C32.3802 34.9451 32.0744 35.1763 32.0744 35.4894C32.0744 36.4238 34.5837 35.6604 34.5837 37.1992C34.5837 37.9168 33.9263 38.372 32.8739 38.372C32.2165 38.372 31.5662 38.1697 31.2387 37.9096L31.2363 37.9072Z" fill="#5B636D"/>
		<path d="M35.1133 36.3442C35.1133 35.1642 35.9875 34.3262 37.1747 34.3262C38.3619 34.3262 39.2289 35.1618 39.2289 36.3442C39.2289 37.5267 38.3619 38.3695 37.1747 38.3695C35.9875 38.3695 35.1133 37.5242 35.1133 36.3442ZM38.5064 36.3442C38.5064 35.5014 37.9381 34.9547 37.1771 34.9547C36.4161 34.9547 35.8406 35.499 35.8406 36.3442C35.8406 37.1895 36.4161 37.741 37.1771 37.741C37.9381 37.741 38.5064 37.1895 38.5064 36.3442Z" fill="#5B636D"/>
		<path d="M40.2241 32.7827H40.9418V38.3264H40.2241V32.7827Z" fill="#5B636D"/>
		<path d="M45.9965 34.3647V38.3238H45.3174V37.7266C45.026 38.1384 44.5323 38.3696 43.9736 38.3696C42.9501 38.3696 42.271 37.8085 42.271 36.6429V34.3647H42.9886V36.561C42.9886 37.3389 43.3764 37.7266 44.0579 37.7266C44.8044 37.7266 45.2836 37.2642 45.2836 36.4117V34.3647H46.0013H45.9965Z" fill="#5B636D"/>
		<path d="M50.158 37.1751L50.7023 37.5195C50.3892 38.0734 49.8064 38.372 49.1033 38.372L49.0142 38.6995C49.4476 38.7453 49.6644 38.9909 49.6644 39.3208C49.6644 39.7398 49.2983 39.9999 48.7372 39.9999C48.4747 39.9999 48.258 39.9469 48.0726 39.8362L48.2291 39.4701C48.3856 39.5592 48.5494 39.5978 48.7372 39.5978C49.0214 39.5978 49.1562 39.487 49.1562 39.2991C49.1562 39.1282 49.0286 39.0078 48.73 39.0078H48.5277L48.6987 38.3431C47.6897 38.1793 46.9961 37.4015 46.9961 36.3491C46.9961 35.1691 47.8703 34.3311 49.0888 34.3311C49.7992 34.3311 50.3892 34.6224 50.7023 35.1763L50.158 35.5279C49.9052 35.1402 49.5151 34.9596 49.0816 34.9596C48.3037 34.9596 47.721 35.5038 47.721 36.3491C47.721 37.1944 48.3037 37.7459 49.0816 37.7459C49.5151 37.7459 49.9028 37.5677 50.158 37.1775V37.1751Z" fill="#5B636D"/>
		<path d="M51.2031 36.3442C51.2031 35.1642 52.0773 34.3261 53.2645 34.3261C54.4518 34.3261 55.3187 35.1618 55.3187 36.3442C55.3187 37.5266 54.4518 38.3695 53.2645 38.3695C52.0773 38.3695 51.2031 37.5242 51.2031 36.3442ZM54.5939 36.3442C54.5939 35.5013 54.0255 34.9547 53.2645 34.9547C52.5035 34.9547 51.928 35.4989 51.928 36.3442C51.928 37.1895 52.5035 37.7409 53.2645 37.7409C54.0255 37.7409 54.5939 37.1895 54.5939 36.3442ZM52.7877 33.2665C52.571 33.2665 52.4217 33.4375 52.4072 33.7289H52.0026C52.0171 33.175 52.3013 32.8186 52.7347 32.8186C53.214 32.8186 53.4524 33.3267 53.7293 33.3267C53.9461 33.3267 54.0954 33.1557 54.1098 32.8788H54.5144C54.4999 33.4086 54.2158 33.7674 53.7823 33.7674C53.3031 33.7674 53.0647 33.2665 52.7877 33.2665Z" fill="#5B636D"/>
		<path d="M59.875 36.585H56.6625C56.7516 37.2786 57.3127 37.7434 58.0978 37.7434C58.5601 37.7434 58.9503 37.5869 59.2417 37.2642L59.6366 37.7265C59.2778 38.1456 58.7335 38.3695 58.0761 38.3695C56.7998 38.3695 55.9473 37.5242 55.9473 36.3442C55.9473 35.1642 56.7925 34.3262 57.934 34.3262C59.0755 34.3262 59.8919 35.1474 59.8919 36.3659C59.8919 36.4261 59.8847 36.5152 59.8774 36.5826L59.875 36.585ZM56.6625 36.0697H59.2031C59.1285 35.405 58.6276 34.933 57.934 34.933C57.2405 34.933 56.7396 35.3954 56.6649 36.0697H56.6625Z" fill="#5B636D"/>
		<path d="M60.3828 37.9072L60.6814 37.3389C61.0186 37.5773 61.5556 37.7507 62.071 37.7507C62.7356 37.7507 63.0126 37.5484 63.0126 37.2137C63.0126 36.325 60.5105 37.0932 60.5105 35.5183C60.5105 34.8079 61.1462 34.3311 62.1625 34.3311C62.6778 34.3311 63.2606 34.4659 63.605 34.6899L63.2991 35.2582C62.9403 35.027 62.5454 34.9451 62.1552 34.9451C61.5267 34.9451 61.2209 35.1763 61.2209 35.4894C61.2209 36.4238 63.7302 35.6604 63.7302 37.1992C63.7302 37.9168 63.0728 38.372 62.0204 38.372C61.3629 38.372 60.7127 38.1697 60.3852 37.9096L60.3828 37.9072Z" fill="#5B636D"/>
		<path d="M71.3713 36.8982C71.3713 37.8013 70.6537 38.3239 69.2786 38.3239H66.5742V33.0957H69.1293C70.4369 33.0957 71.1088 33.64 71.1088 34.4563C71.1088 34.9789 70.8391 35.3835 70.4153 35.6074C70.9981 35.7953 71.3713 36.2432 71.3713 36.9006V36.8982ZM67.7759 34.006V35.239H68.9776C69.5676 35.239 69.8879 35.0295 69.8879 34.6177C69.8879 34.2059 69.5676 34.006 68.9776 34.006H67.7759ZM70.1528 36.7706C70.1528 36.3299 69.8156 36.1204 69.1895 36.1204H67.7783V37.4136H69.1895C69.818 37.4136 70.1528 37.2185 70.1528 36.7706Z" fill="#5B636D"/>
		<path d="M74.7019 34.2468V35.3233C74.6056 35.3161 74.5309 35.3088 74.4394 35.3088C73.7964 35.3088 73.3702 35.6604 73.3702 36.4286V38.3263H72.2046V34.307H73.3172V34.8368C73.6013 34.4491 74.0782 34.2468 74.6995 34.2468H74.7019Z" fill="#5B636D"/>
		<path d="M78.9288 36.0313V38.3239H77.8378V37.823C77.6211 38.189 77.2021 38.3841 76.6121 38.3841C75.6705 38.3841 75.1094 37.8615 75.1094 37.1655C75.1094 36.4696 75.6103 35.9638 76.836 35.9638H77.7632C77.7632 35.4629 77.4646 35.1715 76.836 35.1715C76.4098 35.1715 75.9691 35.3136 75.6777 35.5448L75.2587 34.7308C75.6994 34.4178 76.3496 34.2444 76.9926 34.2444C78.2183 34.2444 78.9288 34.8127 78.9288 36.0289V36.0313ZM77.7632 37.0547V36.6429H76.9637C76.4194 36.6429 76.246 36.8452 76.246 37.1125C76.246 37.4039 76.4917 37.599 76.9035 37.599C77.2912 37.599 77.6283 37.4184 77.7632 37.0547Z" fill="#5B636D"/>
		<path d="M79.5927 37.9506L79.9805 37.1149C80.3393 37.3461 80.8835 37.5027 81.3772 37.5027C81.9142 37.5027 82.1165 37.3606 82.1165 37.1366C82.1165 36.4792 79.6891 37.1511 79.6891 35.5448C79.6891 34.7838 80.3754 34.2444 81.5482 34.2444C82.1021 34.2444 82.7138 34.372 83.0942 34.596L82.7065 35.4244C82.3116 35.2004 81.9142 35.1258 81.5482 35.1258C81.0256 35.1258 80.8017 35.2895 80.8017 35.4991C80.8017 36.1854 83.2291 35.5207 83.2291 37.1053C83.2291 37.8519 82.5355 38.3817 81.3315 38.3817C80.6523 38.3817 79.9636 38.1938 79.5903 37.9482L79.5927 37.9506Z" fill="#5B636D"/>
		<path d="M88.3254 36.3154C88.3254 37.5773 87.4512 38.3841 86.3241 38.3841C85.8232 38.3841 85.4114 38.2275 85.1128 37.8976V39.7736H83.9473V34.3046H85.0598V34.767C85.3512 34.4154 85.7847 34.2444 86.3217 34.2444C87.4488 34.2444 88.3229 35.0511 88.3229 36.313L88.3254 36.3154ZM87.1453 36.3154C87.1453 35.6219 86.7046 35.2028 86.1219 35.2028C85.5391 35.2028 85.0984 35.6219 85.0984 36.3154C85.0984 37.009 85.5391 37.428 86.1219 37.428C86.7046 37.428 87.1453 37.009 87.1453 36.3154Z" fill="#5B636D"/>
		<path d="M92.5899 36.0313V38.3239H91.499V37.823C91.2823 38.189 90.8632 38.3841 90.2732 38.3841C89.3316 38.3841 88.7705 37.8615 88.7705 37.1655C88.7705 36.4696 89.2714 35.9638 90.4972 35.9638H91.4243C91.4243 35.4629 91.1257 35.1715 90.4972 35.1715C90.0709 35.1715 89.6302 35.3136 89.3388 35.5448L88.9198 34.7308C89.3605 34.4178 90.0107 34.2444 90.6537 34.2444C91.8795 34.2444 92.5899 34.8127 92.5899 36.0289V36.0313ZM91.4243 37.0547V36.6429H90.6248C90.0806 36.6429 89.9072 36.8452 89.9072 37.1125C89.9072 37.4039 90.1528 37.599 90.5646 37.599C90.9523 37.599 91.2895 37.4184 91.4243 37.0547Z" fill="#5B636D"/>
		<path d="M97.8347 34.3046V37.652C97.8347 39.145 97.0279 39.8338 95.5782 39.8338C94.8172 39.8338 94.0755 39.646 93.6059 39.2799L94.0683 38.4443C94.4126 38.7212 94.9713 38.8994 95.4867 38.8994C96.3079 38.8994 96.6667 38.5261 96.6667 37.8013V37.6303C96.3609 37.9674 95.9202 38.1312 95.3976 38.1312C94.285 38.1312 93.3892 37.3606 93.3892 36.1878C93.3892 35.015 94.285 34.2444 95.3976 34.2444C95.9587 34.2444 96.4211 34.4322 96.7269 34.8199V34.3046H97.8323H97.8347ZM96.6836 36.1878C96.6836 35.5978 96.2429 35.2028 95.6312 35.2028C95.0195 35.2028 94.5692 35.5978 94.5692 36.1878C94.5692 36.7778 95.0171 37.1727 95.6312 37.1727C96.2453 37.1727 96.6836 36.7778 96.6836 36.1878Z" fill="#5B636D"/>
		<path d="M95.9279 24.1206H2.45166" stroke="#5A636D" strokeOpacity="0.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
}
